import imgProfile from "../img/profile.ico";
export const playlistSetMax = 10;
export const nightTheme = {
  "--back-menu": "#0f9393b3",
  "--background-even": "#1e282e",
  "--background-first": "#1d2225",
  "--background-header": "#252c2f",
  "--background-intense": "#808080",
  "--background-nav": "#011e16",
  "--background-note": "#1f4f5c",
  "--background-parts-answ": "#0f9393b3",
  "--background-second": "#252c2f",
  "--background-third": "#053c29",
  "--background-wrap": "#1d1b1b",
  "--background-wrap-rgb": "rgb(29, 27, 27, 0)",
  "--border-fill": "#DADEE8",
  "--color-about": "#128867",
  "--color-back": "#000000",
  "--color-bg-wrap": "#86909b",
  "--color-checked": "#0f9393b3",
  "--color-fill": "#0e4949",
  "--color-intense": "#1db3b3",
  "--color-menuG": "#1db3b3",
  "--color-nav": "#0f9393b3",
  "--color-rate": "#1db3b3",
  "--color-text": "#b5a68a",
  "--contrast-text": "#ffffff",
  "--white-black": "#e9e8e6",
  "--wrap-opacity": "0",
  "--shadow-size": "-1px",
};

export const dayTheme = {
  "--back-menu": "#2a313e",
  "--background-even": "#F7F6F6",
  "--background-first": "#fcfbf7",
  "--background-header": "#f7f6f6",
  "--background-intense": "#0A58CA",
  "--background-nav": "#5c8ece",
  "--background-note": "#5c8ece",
  "--background-parts-answ": "#ffebcd",
  "--background-second": "#ededed",
  "--background-third": "#337AB7",
  "--background-wrap": "#ffffff",
  "--background-wrap-rgb": "rgb(252, 252, 252)",
  "--border-fill": "#DADEE8",
  "--color-about": "#5d8fd0",
  "--color-back": "#e9e8e6",
  "--color-bg-wrap": "#5f6368",
  "--color-checked": "#ed666d",
  "--color-fill": "#EDEFF4",
  "--color-intense": "#597db4",
  "--color-menuG": "#5f6368",
  "--color-nav": "#5c8ece",
  "--color-rate": "#64b5f6",
  "--color-text": "#000000",
  "--contrast-text": "#000000",
  "--white-black": "#5f6368",
  "--shadow-size": "6px",
  // "--wrap-opacity": "0",
};
export const defaultColorBack = "#ffffff";
export const defaultStyleValue = {
  colorBack: dayTheme["--color-back"],
  colorBackN: nightTheme["--color-back"],
  wrapOpacity: 100,
};
export const defaultSettings = { colorBack: defaultColorBack };
export const defaultAvatar = imgProfile;
export const DEFAUL_USER_DATA = {
  name: "",
  email: "",
  img: defaultAvatar,
  password: "",
  settings: defaultSettings,
};
